import { Link } from "react-router-dom";
import "./HeroSection.css";

const HeroSection = () => {
  return (
    <div className="staticSlider" style={{ background: "url(/images/banner.webp) no-repeat center/cover" }}>
      <div className="container">
        <div className="slidewrap">
          <h3>Welcome to </h3>
          <h1>Sagatom Engineering Limited</h1>
          <p>The Ultimate Solution</p>
          <Link to="/" className="btn btn-default section-btn">
            Get Started
          </Link>
          <Link className="btn btn-default section-btn span_color" to="/contact">
            Contact Us
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
