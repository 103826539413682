import "./Map.css";

const Map = () => {
  return (
    <div className="xs-map-sec">
      <div className="xs-maps-wraper">
        <div className="map">
          <iframe width={100} height={600} src="https://maps.google.com/maps?width=100%25&height=600&hl=en&q=Ruposhi%20Pro%20active%20village%20+(sagatom%20engineering%20limited)&t=&z=14&ie=UTF8&iwloc=B&output=embed" title="map" />
        </div>
      </div>
    </div>
  );
};

export default Map;
