import "./ProjectsIsotope.css";
import React from "react";
import Isotope from "isotope-layout";
import imagesLoaded from "imagesloaded";

const ProjectsIsotope = () => {
  // init one ref to store the future isotope object
  const isotope = React.useRef();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = React.useState("*");

  // initialize an Isotope object with configs
  React.useEffect(() => {
    isotope.current = new Isotope(".filter-container", {
      itemSelector: ".filter-item",
      layoutMode: "fitRows",
    });

    // Use imagesLoaded to ensure Isotope arranges items after all images are loaded
    imagesLoaded(".grid").on("progress", () => {
      isotope.current.layout();
    });

    // cleanup
    return () => isotope.current.destroy();
  }, []);

  // handling filter key change
  React.useEffect(() => {
    filterKey === "*" ? isotope.current.arrange({ filter: `*` }) : isotope.current.arrange({ filter: `.${filterKey}` });
  }, [filterKey]);

  const handleFilterKeyChange = (key) => () => setFilterKey(key);

  return (
    <section className="projects-area">
      <div className="container-fluid">
        <div className="section-title">
          <h3>
            OUR Featuring <span>Projects</span>
          </h3>
          <p>Featuring our works ongoing projects portfolio and snapshots</p>
        </div>

        <div className="row">
          <ul className="container-filter categories-filter">
            <li>
              <a className="categories button-active" onClick={handleFilterKeyChange("*")}>
                All Projects
              </a>
            </li>
            <li>
              <a className="categories hvr-link" onClick={handleFilterKeyChange("architectural")}>
                architectural work
              </a>
            </li>
            <li>
              <a className="categories hvr-link" onClick={handleFilterKeyChange("civil")}>
                Civil Work
              </a>
            </li>
            <li>
              <a className="categories hvr-link" onClick={handleFilterKeyChange("steel")}>
                steel Work
              </a>
            </li>
            <li>
              <a className="categories hvr-link" onClick={handleFilterKeyChange("mechanical")}>
                mechanical Work
              </a>
            </li>
            <li>
              <a className="categories hvr-link" onClick={handleFilterKeyChange("electrical")}>
                electrical Work
              </a>
            </li>
          </ul>
        </div>

        <div className="portfolio-inner">
          <ul className="row container-masonry portfolio-posts grid filter-container">
            <li className="col-md-3 col-sm-6 col-xs-12 filter-item architectural">
              <div className="image-hover-effect-2">
                <img src="images/services-carousel/services-1.webp" alt="" />
                <div className="caption">
                  <h3>architectural work</h3>
                </div>
              </div>
            </li>

            <li className="col-md-3 col-sm-6 col-xs-12 nf-item filter-item architectural">
              <div className="image-hover-effect-2">
                <img src="images/services-carousel/services-2.webp" alt="" />
                <div className="caption">
                  <h3>architectural work</h3>
                </div>
              </div>
            </li>

            <li className="col-md-3 col-sm-6 col-xs-12 nf-item grid-sizer filter-item architectural">
              <div className="image-hover-effect-2">
                <img src="images/services-carousel/services-3.webp" alt="" />
                <div className="caption">
                  <h3>architectural work</h3>
                </div>
              </div>
            </li>

            <li className="col-md-3 col-sm-6 col-xs-12 nf-item filter-item architectural">
              <div className="image-hover-effect-2">
                <img src="images/services-carousel/services-4.webp" alt="" />
                <div className="caption">
                  <h3>architectural work</h3>
                </div>
              </div>
            </li>

            <li className="col-md-3 col-sm-6 col-xs-12 nf-item filter-item architectural">
              <div className="image-hover-effect-2">
                <img src="images/services-carousel/services-5.webp" alt="" />
                <div className="caption">
                  <h3>architectural work</h3>
                </div>
              </div>
            </li>

            <li className="col-md-3 col-sm-6 col-xs-12 nf-item filter-item architectural">
              <div className="image-hover-effect-2">
                <img src="images/services-carousel/services-6.webp" alt="" />
                <div className="caption">
                  <h3>architectural work</h3>
                </div>
              </div>
            </li>

            <li className="col-md-3 col-sm-6 col-xs-12 nf-item filter-item architectural">
              <div className="image-hover-effect-2">
                <img src="images/services-carousel/services-7.webp" alt="" />
                <div className="caption">
                  <h3>architectural work</h3>
                </div>
              </div>
            </li>

            <li className="col-md-3 col-sm-6 col-xs-12 nf-item filter-item architectural">
              <div className="image-hover-effect-2">
                <img src="images/services-carousel/services-8.webp" alt="" />
                <div className="caption">
                  <h3>architectural work</h3>
                </div>
              </div>
            </li>

            <li className="col-md-3 col-sm-6 col-xs-12 nf-item filter-item civil">
              <div className="image-hover-effect-2">
                <img src="images/services-carousel/services-9.webp" alt="" />
                <div className="caption">
                  <h3>Civil Work</h3>
                </div>
              </div>
            </li>

            <li className="col-md-3 col-sm-6 col-xs-12 nf-item filter-item civil">
              <div className="image-hover-effect-2">
                <img src="images/services-carousel/services-10.webp" alt="" />
                <div className="caption">
                  <h3>Civil Work</h3>
                </div>
              </div>
            </li>

            <li className="col-md-3 col-sm-6 col-xs-12 nf-item filter-item civil">
              <div className="image-hover-effect-2">
                <img src="images/services-carousel/services-11.webp" alt="" />
                <div className="caption">
                  <h3>Civil Work</h3>
                </div>
              </div>
            </li>

            <li className="col-md-3 col-sm-6 col-xs-12 nf-item filter-item civil">
              <div className="image-hover-effect-2">
                <img src="images/services-carousel/services-12.webp" alt="" />
                <div className="caption">
                  <h3>Civil Work</h3>
                </div>
              </div>
            </li>

            <li className="col-md-3 col-sm-6 col-xs-12 nf-item filter-item civil">
              <div className="image-hover-effect-2">
                <img src="images/services-carousel/services-13.webp" alt="" />
                <div className="caption">
                  <h3>Civil Work</h3>
                </div>
              </div>
            </li>

            <li className="col-md-3 col-sm-6 col-xs-12 nf-item filter-item steel">
              <div className="image-hover-effect-2">
                <img src="images/services-carousel/services-14.webp" alt="" />
                <div className="caption">
                  <h3>steel Work</h3>
                </div>
              </div>
            </li>

            <li className="col-md-3 col-sm-6 col-xs-12 nf-item filter-item steel">
              <div className="image-hover-effect-2">
                <img src="images/services-carousel/services-15.webp" alt="" />
                <div className="caption">
                  <h3>steel Work</h3>
                </div>
              </div>
            </li>

            <li className="col-md-3 col-sm-6 col-xs-12 nf-item filter-item steel">
              <div className="image-hover-effect-2">
                <img src="images/services-carousel/services-16.webp" alt="" />
                <div className="caption">
                  <h3>steel Work</h3>
                </div>
              </div>
            </li>

            <li className="col-md-3 col-sm-6 col-xs-12 nf-item filter-item mechanical">
              <div className="image-hover-effect-2">
                <img src="images/services-carousel/services-18.webp" alt="" />
                <div className="caption">
                  <h3>Mechanical Work</h3>
                </div>
              </div>
            </li>

            <li className="col-md-3 col-sm-6 col-xs-12 nf-item filter-item mechanical">
              <div className="image-hover-effect-2">
                <img src="images/services-carousel/services-19.webp" alt="" />
                <div className="caption">
                  <h3>Mechanical Work</h3>
                </div>
              </div>
            </li>

            <li className="col-md-3 col-sm-6 col-xs-12 nf-item filter-item mechanical">
              <div className="image-hover-effect-2">
                <img src="images/services-carousel/services-20.webp" alt="" />
                <div className="caption">
                  <h3>Mechanical Work</h3>
                </div>
              </div>
            </li>

            <li className="col-md-3 col-sm-6 col-xs-12 nf-item filter-item mechanical">
              <div className="image-hover-effect-2">
                <img src="images/services-carousel/services-21.webp" alt="" />
                <div className="caption">
                  <h3>Mechanical Work</h3>
                </div>
              </div>
            </li>

            <li className="col-md-3 col-sm-6 col-xs-12 nf-item filter-item mechanical">
              <div className="image-hover-effect-2">
                <img src="images/services-carousel/services-22.webp" alt="" />
                <div className="caption">
                  <h3>Mechanical Work</h3>
                </div>
              </div>
            </li>

            <li className="col-md-3 col-sm-6 col-xs-12 nf-item filter-item mechanical">
              <div className="image-hover-effect-2">
                <img src="images/services-carousel/services-23.webp" alt="" />
                <div className="caption">
                  <h3>Mechanical Work</h3>
                </div>
              </div>
            </li>

            <li className="col-md-3 col-sm-6 col-xs-12 nf-item filter-item mechanical">
              <div className="image-hover-effect-2">
                <img src="images/services-carousel/services-24.webp" alt="" />
                <div className="caption">
                  <h3>Mechanical Work</h3>
                </div>
              </div>
            </li>

            <li className="col-md-3 col-sm-6 col-xs-12 nf-item filter-item mechanical">
              <div className="image-hover-effect-2">
                <img src="images/services-carousel/services-25.webp" alt="" />
                <div className="caption">
                  <h3>Mechanical Work</h3>
                </div>
              </div>
            </li>

            <li className="col-md-3 col-sm-6 col-xs-12 nf-item filter-item mechanical">
              <div className="image-hover-effect-2">
                <img src="images/services-carousel/services-26.webp" alt="" />
                <div className="caption">
                  <h3>Mechanical Work</h3>
                </div>
              </div>
            </li>

            <li className="col-md-3 col-sm-6 col-xs-12 nf-item filter-item mechanical">
              <div className="image-hover-effect-2">
                <img src="images/services-carousel/services-27.webp" alt="" />
                <div className="caption">
                  <h3>Mechanical Work</h3>
                </div>
              </div>
            </li>

            <li className="col-md-3 col-sm-6 col-xs-12 nf-item filter-item electrical">
              <div className="image-hover-effect-2">
                <img src="images/services-carousel/services-28.webp" alt="" />
                <div className="caption">
                  <h3>electrical Work</h3>
                </div>
              </div>
            </li>

            <li className="col-md-3 col-sm-6 col-xs-12 nf-item filter-item electrical">
              <div className="image-hover-effect-2">
                <img src="images/services-carousel/services-29.webp" alt="" />
                <div className="caption">
                  <h3>electrical Work</h3>
                </div>
              </div>
            </li>

            <li className="col-md-3 col-sm-6 col-xs-12 nf-item filter-item electrical">
              <div className="image-hover-effect-2">
                <img src="images/services-carousel/services-30.webp" alt="" />
                <div className="caption">
                  <h3>electrical Work</h3>
                </div>
              </div>
            </li>

            <li className="col-md-3 col-sm-6 col-xs-12 nf-item filter-item electrical">
              <div className="image-hover-effect-2">
                <img src="images/services-carousel/services-31.webp" alt="" />
                <div className="caption">
                  <h3>electrical Work</h3>
                </div>
              </div>
            </li>

            <li className="col-md-3 col-sm-6 col-xs-12 nf-item filter-item electrical">
              <div className="image-hover-effect-2">
                <img src="images/services-carousel/services-32.webp" alt="" />
                <div className="caption">
                  <h3>electrical Work</h3>
                </div>
              </div>
            </li>

            <li className="col-md-3 col-sm-6 col-xs-12 nf-item filter-item electrical">
              <div className="image-hover-effect-2">
                <img src="images/services-carousel/services-33.webp" alt="" />
                <div className="caption">
                  <h3>electrical Work</h3>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default ProjectsIsotope;
