import AboutCompany from "../components/AboutCompany/AboutCompany";
import Footer from "../components/Footer/Footer";
import GetQuote from "../components/GetQuote/GetQuote";
import InnerHeading from "../components/InnerHeading/InnerHeading";
import ManagementProfile from "../components/ManagementProfile/ManagementProfile";
import ValueableClients from "../components/ValuableClients/ValuableClients";
import WriteUs from "../components/WriteUs/WriteUs";
import Navbar from "../components/Navbar/Navbar";
import AgreementOfAffiliation from "../components/AgreementOfAffiliation/AgreementOfAffiliation";
import { useEffect } from "react";
import JointVenture from "../components/JointVenture/JointVenture";

const AboutUsPage = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <>
      <Navbar />
      <InnerHeading heading="About Us" />
      <AboutCompany />
      <GetQuote />
      <AgreementOfAffiliation />
      <JointVenture />
      <ManagementProfile />
      <WriteUs title="WRITE" span="US" />
      <ValueableClients />
      <Footer />
    </>
  );
};

export default AboutUsPage;
