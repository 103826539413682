import { NavLink, Link } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  return (
    <header>
      <div className="container">
        <div className="row">
          <div className="nav">
            <input type="checkbox" id="nav-check" />
            <div className="nav-header">
              {/* <div className="nav-title">JoGeek</div> */}
              <div className="nav-logo">
                <Link to="/">
                  <img src="/images/logo.svg" alt="sagatom-engineering-limited" title="" />
                </Link>
              </div>
            </div>
            <div className="nav-btn">
              <label htmlFor="nav-check">
                <span />
                <span />
                <span />
              </label>
            </div>
            <div className="nav-links">
              <NavLink to="/">Home</NavLink>
              <NavLink to="/about">About Us</NavLink>
              <NavLink to="/services">Services</NavLink>
              <NavLink to="/projects">Projects</NavLink>
              <NavLink to="/contact">Contact Us</NavLink>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
