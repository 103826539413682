import "./JointVenture.css";

const JointVenture = () => {
  return (
    <div>
      <section className="joint-venture bg-gray">
        <div className="container">
          <div className="section-title">
            <h3>
              Joint Venture <span>with JC (China)</span>
            </h3>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-6 col-xs-12 image-container">
              <img src="images/joint-venture-handshake.webp" alt="" className="img-responsive img-rounded" />
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12 image-container">
              <img src="images/joint-venture-agreement.webp" alt="" className="img-responsive img-rounded" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default JointVenture;
