import AboutInfo from "../components/AboutInfo/AboutInfo";
import BestServices from "../components/BestServices/BestServices";
import Counter from "../components/Counter/Counter";
import HeroSection from "../components/HeroSection/HeroSection";
import Navbar from "../components/Navbar/Navbar";
import WhyChooseUs from "../components/WhyChooseUs/WhyChooseUs";
import ValueableClients from "../components/ValuableClients/ValuableClients";
import Footer from "../components/Footer/Footer";
import PortfolioCarousel from "../components/PortfolioCarousel/PortfolioCarousel";
import WriteUs from "../components/WriteUs/WriteUs";

const HomePage = () => {
  return (
    <>
      <Navbar />
      <HeroSection />
      <AboutInfo />
      <BestServices />
      <Counter />
      <PortfolioCarousel />
      <WhyChooseUs />
      <WriteUs title="WRITE" span="US" />
      <ValueableClients />
      <Footer />
    </>
  );
};

export default HomePage;
