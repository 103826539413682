import "./AgreementOfAffiliation.css";

const AgreementOfAffiliation = () => {
  return (
    <section className="agreement-affiliation">
      <div className="container">
        <div className="section-title">
          <h3>
            Agreement Of <span>Affiliation</span>
          </h3>
        </div>
        <div className="row">
          <div className="img-block">
            <img className="img-responsive" src="images/Agreement-Affiliation.png" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AgreementOfAffiliation;
