import "./GetInTouch.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

const GetInTouch = () => {
  return (
    <section className="contactWrap bg-gray">
      <div className="container">
        <div className="section-title">
          <h3>
            Get in <span>touch</span>
          </h3>
          <p>Questions? Comments? Contact us to discuss anything you'd like, or simply drop a line to say hi. We welcome to give you a free consultancy fory our project.</p>
        </div>

        <div className="row ">
          <div className="serviceList">
            <div className="contact-item">
              <div className="fig_caption">
                <div className="icon">
                  <FontAwesomeIcon icon={faLocationDot} />
                </div>
                <div className="details">
                  <h3>Live Discussion</h3>
                  <p> House 1, Road 3, Section 6, Mirpur, Dhaka 1216</p>
                </div>
              </div>
            </div>

            <div className="contact-item">
              <div className="fig_caption">
                <div className="icon">
                  <FontAwesomeIcon icon={faEnvelope} />
                </div>
                <div className="details">
                  <h3>Mail Us</h3>
                  <p>
                    info@sagatom.com
                    <br />
                    sagatom2u@gmail.com
                  </p>
                </div>
              </div>
            </div>

            <div className="contact-item">
              <div className="fig_caption">
                <div className="icon">
                  <FontAwesomeIcon icon={faPhone} />
                </div>
                <div className="details">
                  <h3>Call Us</h3>
                  <p>
                    <strong>CALL US NOW </strong> <br />
                    +88 01740-214622
                    <br />
                    +88 01912-137844
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetInTouch;
