import "./ManagementProfile.css";

const ManagementProfile = () => {
  return (
    <section className="management-profile">
      <div className="container">
        <div className="section-title">
          <h3>
            Profile Of <span>Management</span>
          </h3>
        </div>
      </div>
      <div className="about-management">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-md-push-6 col-sm-12">
              <div className="section-title">
                <h3>
                  CEO talks
                  <br />
                  <span>Engr. Md. Waliul Kawsar</span>
                </h3>
                <h4>Founder & Chief Executive Officer</h4>
                <p>Studied Electrical and Electronic Engineering in Ahsanullah University of Science and Technology. He Has over {new Date().getFullYear() - 2007} years professional experience in Project Management, Procurement, Construction, Installation, Erection, Operation, Maintenance, Quality- Assurance of Industrial, Commercial Projects and Served well named National and Multinational companies like, Rangs Group, Nassa Group, Center for Engineering Studies & Design (Egyptian), Bensim Ltd. (Turkish) Also worked in Consortium of Vistaara Architectures Pvt. Ltd. Worked in Milestone Projects like Grameen Phone House(GP House), American International School Dhaka. He completed successfully so many Industrial, Residential & Commercial projects.</p>
              </div>
            </div>
            <div className="col-md-6 col-md-pull-6 col-sm-12">
              <div className="about-img">
                <img src="/images/CEO.webp" className="img-responsive" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-management bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="section-title">
                <h3>
                  <span>Engr. Habib E Khoda</span>
                </h3>
                <h4>Advisor</h4>
                <p>Studied Mechanical Engineering in Bangladesh University of Engineering and Technology and International Business Programme in Indian Institute of Management, Calcutta . He has over 48 years’ professional experience out of which was carried M/S Commonwealth Associates Inc. &amp; NRECA, BREB, Nassa Group, and other group of companies with a vast experience in Construction, Operation, Maintenance, Quality- Assurance, Consultancy and Procurement for Industrial, Commercial and Government Projects.</p>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="about-img">
                <img src="/images/advisor-1.webp" className="img-responsive" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-management">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-md-push-6 col-sm-12">
              <div className="section-title">
                <h3>
                  <span>Engr. Md. Maswood Anwar</span>
                </h3>
                <h4>Advisor</h4>
                <p>Studied Electrical Engineering in Bangladesh University of Engineering and Technology and Post-Graduation in Germany. He has over 48 years’ professional experience out of which 34 years overseas in Germany and United Arab Emirates (UAE), and 14 years in Bangladesh. His experiences include Construction, Operation, Maintenance, Quality- Assurance, Consultancy and Procurement of Electrical Equipment and System for Industrial, Commercial and Government Projects. As an officer he also served Engineering Corps of UAE Armed Forces for 20 yrs.</p>
              </div>
            </div>
            <div className="col-md-6 col-md-pull-6 col-sm-12">
              <div className="about-img">
                <img src="/images/advisor-2.webp" className="img-responsive" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-management bg-gray">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="section-title">
                <h3>
                  <span>Engr. Md. Golam Kibria Chowdhury</span>
                </h3>
                <h4>Advisor</h4>
                <p>Studied Civil Engineering in Bangladesh University of Engineering. He has over 44 years’ professional experience out of which was carried Military Engineer Services, NAVANA Group, Karim Group (Wahid Constraction), PFI Properties Ltd,A Land Associates, Vistara Architects Pvt. Ltd., Mazid sons constraction Ltd. and other companies with a vast experience in Project Management, Construction, Quality- Assurance, Consultancy, Procurement for Industrial, Commercial and Government Projects.</p>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="about-img">
                <img src="/images/advisor-3.webp" className="img-responsive" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ManagementProfile;
